import { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Button,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
    InputGroup,
    InputGroupText,
    FormGroup,
    ListGroupItem,
} from "reactstrap";
import makeid from "helpers/random";
import { InputSwitch } from "primereact/inputswitch";
import { ProgressSpinner } from "primereact/progressspinner";
import { handleThousandSeparatorChange, removeCommas, formatThousandSeparator } from "helpers/functions";
import Select from "react-select"

// Formik validation
import * as Yup from "yup";
import { useFormik, FieldArray, Form as FormFormik, FormikProvider } from "formik";
import { Calendar } from 'primereact/calendar';
import moment from "moment";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import "../../../../assets/css/style.css";
import usePartner from "hooks/usePartner";
import useAuth from "hooks/useAuth";

const TON_TO_LITER = 1000; //fake value. they already input 1000 on the data

const EditVolumePartner = ({ rowData }) => {
    const { showToast } = useAuth()
    const axiosApi = useAxiosPrivate();
    const { setPartnerRefresh } = usePartner();

    const [modal_center, setmodal_center] = useState(false);
    const [selectedBoxes, setSelectedBoxes] = useState([])
    const [boxes, setBoxes] = useState([])

    const today = new Date();
    const [date, setDate] = useState(new Date());

    const handleMonthPrice = (e) => {
        validation.handleChange(e); let { name, value } = e.target;
        value = value.replace(/[^0-9]/g, ""); // Remove everything except digits
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        if (value === "" || parseFloat(value) <= 0) {
            value = ""; // Optional: Reset value to empty if it's not valid
        }

        const formattedValue = formatThousandSeparator(value);

        validation.setFieldValue(name, formattedValue);
    };

    const getBoxes = async () => {
        try {
            const selectedBoxIds = rowData?.detail?.box_ids || [];
            const partner_configuration_id = rowData?.detail?.partner_configuration_id || null;
            const response = await axiosApi.get(`/api/admin/partner-configurations/box-options?partner_configuration_id=${partner_configuration_id}`);
            const dataList = response.data.result
            let results = []
            let selectedBoxesArr = [];
            if (dataList)
                dataList.forEach((box, index) => {
                    let isLinked = box.is_linked;
                    if (selectedBoxIds.includes(box.id)) {
                        isLinked = false;
                    }
                    var rowData = {
                        ...box,
                        label: box.name, value: box.name, disabled: isLinked
                    }
                    if (selectedBoxIds.includes(box.id)) {
                        selectedBoxesArr.push(rowData)
                    }
                    results.push(rowData);
                });
            setBoxes(results);
            if (selectedBoxesArr.length > 0) {
                setSelectedBoxes(selectedBoxesArr);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const changeBox = (value) => {
        setSelectedBoxes(value)
    }

    useEffect(() => {
        if (modal_center) {
            getBoxes();
        }
    }, [modal_center]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
            }
        }
    }

    const [compensation, setCompensation] = useState(rowData?.detail?.compensation || null);

    const updateCompensation = (value) => {


        setCompensation(value); // Set the selected compensation type
        // setCompensation(prevLevels => {
        //   if (!prevLevels.includes(value)) {
        //     return [...prevLevels, value];
        //   } else {
        //     return prevLevels.filter(level => level !== value);
        //   }
        // });
    };

    const handleMaxChange = (e, tier) => {
        let { name, value } = e.target;
        validation.handleChange(e);
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        // Ensure value is more than 0
        if (value === "" || parseFloat(value) < 0) {
            value = ""; // Optional: Reset value to empty if it's not valid
        }


        // Limit to two decimal places
        const decimalIndex = value.indexOf(".");
        if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
            value = value.substring(0, decimalIndex + 3); // Keep only two digits after decimal
        }

        validation.setFieldValue(name, value);

        // Set the next min value based on the current max value
        if (tier === 1) {
            validation.setFieldValue('min_share_tier_2', value);
        } else if (tier === 2) {
            validation.setFieldValue('min_share_tier_3', value);
        } else if (tier === 3) {
            validation.setFieldValue('min_share_tier_4', value);
        }
    };

    const handleShareChange = (e, tier) => {
        let { name, value } = e.target;
        validation.handleChange(e);
        value = value.replace(/[^0-9.]/g, ""); // Remove everything except digits and dot

        // Ensure only one decimal point can be entered
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
            value = value.substring(0, value.length - 1); // Remove the extra dot
        }

        // Ensure value is more than 0
        if (value === "" || parseFloat(value) < 0) {
            value = ""; // Optional: Reset value to empty if it's not valid
        }


        // Limit to two decimal places
        const decimalIndex = value.indexOf(".");
        if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
            value = value.substring(0, decimalIndex + 3); // Keep only two digits after decimal
        }

        if (value > 100) {
            value = "100"
        }

        validation.setFieldValue(name, value);
    };


    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };



    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            boxes: rowData?.detail?.box_id_details?.map(box => ({
                id: box.id,
                collected_date: box.collected_date
            })) || [],

            // partner_account: rowData?.partner_account || "",
            price_month: rowData?.detail?.fixed_monthly_price ? formatThousandSeparator(String(rowData?.detail?.fixed_monthly_price)) : "",
            min_share_tier_1: String((parseFloat(rowData?.detail?.share_tier?.share_tier_1?.min || 0) / TON_TO_LITER).toFixed(2)),
            max_share_tier_1: String((parseFloat(rowData?.detail?.share_tier?.share_tier_1?.max || 0) / TON_TO_LITER).toFixed(2)),
            min_share_tier_2: String((parseFloat(rowData?.detail?.share_tier?.share_tier_2?.min || 0) / TON_TO_LITER).toFixed(2)),
            max_share_tier_2: String((parseFloat(rowData?.detail?.share_tier?.share_tier_2?.max || 0) / TON_TO_LITER).toFixed(2)),
            min_share_tier_3: String((parseFloat(rowData?.detail?.share_tier?.share_tier_3?.min || 0) / TON_TO_LITER).toFixed(2)),
            max_share_tier_3: String((parseFloat(rowData?.detail?.share_tier?.share_tier_3?.max || 0) / TON_TO_LITER).toFixed(2)),
            min_share_tier_4: String((parseFloat(rowData?.detail?.share_tier?.share_tier_4?.min || 0) / TON_TO_LITER).toFixed(2)),
            // max_share_tier_4: String(rowData?.detail?.share_tier?.share_tier_4?.max || 0),
            percentage_share_tier_1: String(rowData?.detail?.share_tier?.share_tier_1?.percentage || 0),
            percentage_share_tier_2: String(rowData?.detail?.share_tier?.share_tier_2?.percentage || 2.5),
            percentage_share_tier_3: String(rowData?.detail?.share_tier?.share_tier_3?.percentage || 5),
            percentage_share_tier_4: String(rowData?.detail?.share_tier?.share_tier_4?.percentage || 10),

        },
        validationSchema: Yup.object().shape({
            // partner_account: Yup.string().required("This value is required"),
            // box: selectedBoxes.length == 0 ? Yup.array().min(1, "This value is required").required("This value is required") : Yup.array().nullable(),
            boxes: selectedBoxes.length == 0 ? Yup.array().of(
                Yup.object().shape({
                    id: Yup.mixed().required("This value is required"),
                    collected_date: Yup.string().required("This value is required"),
                })
            ).required("This value is required") : Yup.array().nullable(),

            compensations: compensation == null ? Yup.string().required("This value is required") : Yup.string()
                .nullable(),
            price_month: compensation != 'revenue_share'
                ? Yup.string().required("This value is required")
                : Yup.string().nullable(),
            max_share_tier_1: compensation != 'fixed'
                ? Yup.string()
                    .required("This value is required")
                    .test(
                        "less-than-tier-2",
                        "Tier 1 must be less than Tier 2",
                        function (value) {
                            const { max_share_tier_2 } = this.parent;
                            return !max_share_tier_2 || parseFloat(value) < parseFloat(max_share_tier_2);
                        }
                    )
                : Yup.string().nullable(),

            max_share_tier_2: compensation != 'fixed'
                ? Yup.string()
                    .required("This value is required")
                    .test(
                        "less-than-tier-3",
                        "Tier 2 must be less than Tier 3",
                        function (value) {
                            const { max_share_tier_3 } = this.parent;
                            return !max_share_tier_3 || parseFloat(value) < parseFloat(max_share_tier_3);
                        }
                    )
                : Yup.string().nullable(),
            max_share_tier_3: compensation != 'fixed' ? Yup.string().required("This value is required") : Yup.string().nullable(),
            percentage_share_tier_1: compensation != 'fixed'
                ? Yup.string()
                    .required("This value is required")
                : Yup.string().nullable(),
            percentage_share_tier_2: compensation != 'fixed'
                ? Yup.string()
                    .required("This value is required")
                : Yup.string().nullable(),
            percentage_share_tier_3: compensation != 'fixed'
                ? Yup.string()
                    .required("This value is required")
                : Yup.string().nullable(),
            percentage_share_tier_4: compensation != 'fixed' ? Yup.string().required("This value is required") : Yup.string().nullable(),

        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const boxData = values.boxes.map(box => ({
                    id: box.id,
                    collected_date: moment(box.collected_date).format('YYYY-MM-DD')
                }));

                const opts = {
                    // box_ids: selectedBoxes.length == 0 ? [] : selectedBoxes.map(box => box.id),
                    box_ids: boxData,
                    compensation: compensation,
                    fixed_monthly_price: compensation != 'revenue_share' ? (values.price_month ? removeCommas(values.price_month) : "") : "",
                    ...(compensation != 'fixed' && {
                        share_tier: {
                            share_tier_1: {
                                min: (parseFloat(values.min_share_tier_1) * TON_TO_LITER).toString(),
                                max: (parseFloat(values.max_share_tier_1) * TON_TO_LITER).toString(),
                                percentage: values.percentage_share_tier_1
                            },
                            share_tier_2: {
                                min: (parseFloat(values.min_share_tier_2) * TON_TO_LITER).toString(),
                                max: (parseFloat(values.max_share_tier_2) * TON_TO_LITER).toString(),
                                percentage: values.percentage_share_tier_2
                            },
                            share_tier_3: {
                                min: (parseFloat(values.min_share_tier_3) * TON_TO_LITER).toString(),
                                max: (parseFloat(values.max_share_tier_3) * TON_TO_LITER).toString(),
                                percentage: values.percentage_share_tier_3
                            },
                            share_tier_4: {
                                min: (parseFloat(values.min_share_tier_4) * TON_TO_LITER).toString(),
                                // max: values.max_share_tier_4,
                                percentage: values.percentage_share_tier_4
                            }
                        }
                    })

                };

                const response = await axiosApi.put('/api/admin/partner-locations/' + rowData.id, opts);
                setPartnerRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response.data);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        },
    });

    return (
        <>
        <style>
            {`
                .p-datepicker {
                    z-index: 1051 !important;
                }
            `}
        </style>

        <FormikProvider value={validation}>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered size="lg">
                <ModalHeader className="mt-0" toggle={tog_center}>
                    Edit Location Partner | {rowData?.partner_account}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        {/* <div className="mb-3">
                            <Label className="form-label">Partner Account Name</Label>
                            <Input
                                name="partner_account"
                                placeholder="Enter Partner Account Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.partner_account || ""}
                                invalid={
                                    validation.touched.partner_account && validation.errors.partner_account ? true : false
                                }
                            />
                            {validation.touched.partner_account && validation.errors.partner_account ? (
                                <FormFeedback type="invalid">{validation.errors.partner_account}</FormFeedback>
                            ) : null}
                        </div> */}
                        <div className="mb-3">
                            <Label className="form-label">Box</Label>
                            {/* BOXES */}
                            <FieldArray
                                name="boxes"
                                render={arrayHelpers => {
                                    const forms = validation.values.boxes;

                                    return (
                                        <div>
                                            {forms.length === 0 && (
                                            <div className="d-flex flex-wrap">
                                                <Button
                                                    onClick={() => arrayHelpers.push({
                                                        box_ids: null,
                                                        collected_date: moment(date).format('YYYY-MM-DD')
                                                    })} 
                                                    color="primary"
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                            </div>
                                        )}

                                            {forms.map((form, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        {/* BOX */}
                                                        <Col lg={5} md={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Box {index + 1}</Label>
                                                                <Select
                                                                    value={boxes.find((box) => box.id === form?.id) || null}
                                                                    onChange={selectedOption => {
                                                                        validation.setFieldValue(`boxes.${index}.id`, selectedOption.id);
                                                                      }}
                                                                    options={boxes}
                                                                    isOptionDisabled={(option) => {
                                                                        const isSelectedInOtherForm = forms.some(
                                                                            (boxForm, i) => 
                                                                                i !== index && 
                                                                                boxForm.id && 
                                                                                boxForm.id === option.id
                                                                        );
                                                                        return isSelectedInOtherForm;
                                                                    }}
                                                                    getOptionLabel={(option) => option.name}
                                                                    getOptionValue={(option) => option.id}
                                                                    classNamePrefix="select2-selection"
                                                                    styles={{ control: (base) => ({ ...base, height: "47px" }) }}
                                                                />
                                                                {validation.touched.boxes?.[index]?.id && 
                                                                validation.errors.boxes?.[index]?.id && (
                                                                    <div className="error-text">
                                                                        {validation.errors.boxes[index].id}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        {/* DATE */}
                                                        <Col lg={4} md={5}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Collected Date</Label>
                                                                <Calendar
                                                                    value={form.collected_date ? 
                                                                        new Date(form.collected_date) : 
                                                                        date
                                                                    }
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue(
                                                                            `boxes.${index}.collected_date`,
                                                                            moment(e.value).format('YYYY-MM-DD')
                                                                        );
                                                                    }}
                                                                    showIcon
                                                                    dateFormat="yy/mm/dd"
                                                                    locale="en"
                                                                />
                                                                {validation.touched.boxes?.[index]?.collected_date && 
                                                                validation.errors.boxes?.[index]?.collected_date && (
                                                                    <div className="error-text">
                                                                        {validation.errors.boxes[index].collected_date}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        {/* BUTTON REMOVE */}
                                                        <Col lg={1} md={1}>
                                                            <div className="d-flex px-1">
                                                                <Button 
                                                                    className="btn btn-danger" 
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <i className="fas fa-times"></i>
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                            
                                            {/* BUTTON ADD */}
                                            {forms.length > 0 && (
                                                <Row>
                                                    <Col lg={5} md={6}></Col>
                                                    <Col lg={4} md={5}></Col>
                                                    <Col lg={1} md={1}>
                                                        <div className="d-flex px-1">
                                                            {forms.every(form => form.id && form.collected_date) ? (
                                                                <Button 
                                                                    onClick={() => arrayHelpers.push({
                                                                        id: null,
                                                                        collected_date: moment(date).format('YYYY-MM-DD')
                                                                    })} 
                                                                    color="primary"
                                                                >
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            ) : (
                                                                <Button disabled color="primary">
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    );
                                }}
                            />
                        </div>

                        <div className="mb-1">
                            <Label className="form-label">Compensations</Label>
                            <div className="">
                                <FormGroup check key={`fixed`}>
                                    <Input id='fixed' name="compensation[]" type="radio" value='fixed' checked={compensation == 'fixed'} onChange={(e) => { updateCompensation(e.target.value); }} />
                                    <Label htmlFor='fixed'>{`Fixed`}</Label>
                                </FormGroup>
                                <FormGroup check key={`revenue_share`}>
                                    <Input id='revenue_share' name="compensation[]" type="radio" value='revenue_share' checked={compensation == 'revenue_share'} onChange={(e) => { updateCompensation(e.target.value); }} />
                                    <Label htmlFor='revenue_share'>{`Revenue Share`}</Label>
                                </FormGroup>
                                <FormGroup check key={`fixed_revenue_share`}>
                                    <Input id='fixed_revenue_share' name="compensation[]" type="radio" value='fixed_revenue_share' checked={compensation == 'fixed_revenue_share'} onChange={(e) => { updateCompensation(e.target.value); }} />
                                    <Label htmlFor='fixed_revenue_share'>{`Fixed + Revenue Share`}</Label>
                                </FormGroup>
                                {compensation == null && validation.errors.compensations ? (
                                    <div className="error-text mb-3">{validation.errors.compensations}</div>
                                ) : null}
                            </div>
                        </div>
                        {
                            compensation != 'revenue_share' &&
                            <div className="mb-3" >
                                <Label className="form-label">
                                    Fixed Price / month
                                </Label>
                                <div>
                                    <InputGroup
                                        className={
                                            validation.touched.price_month && validation.errors.price_month
                                                ? "is-invalid"
                                                : ""
                                        }
                                        style={{ width: "350px" }}
                                    >
                                        <Input
                                            name="price_month"
                                            placeholder="Enter Fixed Price"
                                            type="text"
                                            autoComplete="off"
                                            inputMode="numeric"
                                            onChange={handleMonthPrice}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.price_month || ""}
                                            invalid={
                                                validation.touched.price_month && validation.errors.price_month
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <InputGroupText>
                                            IDR
                                        </InputGroupText>
                                        {validation.touched.price_month && validation.errors.price_month ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.price_month}
                                            </FormFeedback>
                                        ) : null}
                                    </InputGroup>

                                </div>
                            </div>
                        }
                        {/* Tier 1 */}
                        {
                            compensation != 'fixed' &&
                            <div>
                                <div className="mb-1">
                                    <Label className="form-label mb-0">Share Tiers</Label>
                                    <Row className="">
                                        <Col lg={4} md={4}>
                                            <Label className="col-form-label">Minimum</Label>
                                            <InputGroup className="mb-1">
                                                <Input
                                                    name="min_share_tier_1"
                                                    type="text"
                                                    disabled
                                                    value={0}
                                                    inputMode="numeric"
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            <Label className="col-form-label">Maximum</Label>
                                            <InputGroup className={
                                                validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="max_share_tier_1"
                                                    type="text"
                                                    value={validation.values.max_share_tier_1}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleMaxChange(e, 1)}
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                                {validation.touched.max_share_tier_1 && validation.errors.max_share_tier_1 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.max_share_tier_1}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            <Label className="col-form-label">Share</Label>
                                            <InputGroup className={
                                                validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="percentage_share_tier_1"
                                                    type="text"
                                                    value={validation.values.percentage_share_tier_1}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleShareChange(e, 1)}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                                {validation.touched.percentage_share_tier_1 && validation.errors.percentage_share_tier_1 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.percentage_share_tier_1}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Tier 2 */}
                                <div className="mb-1">
                                    {/* <Label className="form-label">Share Tier 2</Label> */}
                                    <Row className="">
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Minimum</Label> */}
                                            <InputGroup className="mb-1">
                                                <Input
                                                    name="min_share_tier_2"
                                                    type="text"
                                                    disabled
                                                    value={validation.values.min_share_tier_2}
                                                    inputMode="numeric"
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Maximum</Label> */}
                                            <InputGroup className={
                                                validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="max_share_tier_2"
                                                    type="text"
                                                    value={validation.values.max_share_tier_2}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleMaxChange(e, 2)}
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                                {validation.touched.max_share_tier_2 && validation.errors.max_share_tier_2 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.max_share_tier_2}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Share</Label> */}
                                            <InputGroup className={
                                                validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="percentage_share_tier_2"
                                                    type="text"
                                                    value={validation.values.percentage_share_tier_2}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleShareChange(e, 2)}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                                {validation.touched.percentage_share_tier_2 && validation.errors.percentage_share_tier_2 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.percentage_share_tier_2}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Tier 3 */}
                                <div className="mb-1">
                                    {/* <Label className="form-label">Share Tier 3</Label> */}
                                    <Row className="">
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Minimum</Label> */}
                                            <InputGroup className={
                                                validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="min_share_tier_3"
                                                    type="text"
                                                    disabled
                                                    value={validation.values.min_share_tier_3}
                                                    inputMode="numeric"
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Maximum</Label> */}
                                            <InputGroup className="mb-1">
                                                <Input
                                                    name="max_share_tier_3"
                                                    type="text"
                                                    value={validation.values.max_share_tier_3}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleMaxChange(e, 3)}
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                                {validation.touched.max_share_tier_3 && validation.errors.max_share_tier_3 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.max_share_tier_3}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Share</Label> */}
                                            <InputGroup className={
                                                validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="percentage_share_tier_3"
                                                    type="text"
                                                    value={validation.values.percentage_share_tier_3}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleShareChange(e, 3)}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                                {validation.touched.percentage_share_tier_3 && validation.errors.percentage_share_tier_3 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.percentage_share_tier_3}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>

                                {/* Tier 4 */}
                                <div className="mb-1">
                                    {/* <Label className="form-label">Share Tier 4</Label> */}
                                    <Row className="">
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Minimum</Label> */}
                                            <InputGroup className={
                                                    validation.touched.min_share_tier_4 && validation.errors.min_share_tier_4
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="min_share_tier_4"
                                                    type="text"
                                                    disabled
                                                    value={validation.values.min_share_tier_4}
                                                    inputMode="numeric"
                                                />
                                                <InputGroupText>Ton</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Maximum</Label> */}
                                            <InputGroup className="mb-1">
                                                    <Input
                                                        style={{}}
                                                        type="text"
                                                        disabled
                                                        value="&#x221E;"
                                                        inputMode="numeric"
                                                    />
                                                    <InputGroupText >Ton</InputGroupText>
                                            </InputGroup>
                                        </Col>
                                        <Col lg={4} md={4}>
                                            {/* <Label className="col-form-label">Share</Label> */}
                                            <InputGroup className={
                                                validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4
                                                    ? "is-invalid"
                                                    : ""
                                            }>
                                                <Input
                                                    name="percentage_share_tier_4"
                                                    type="text"
                                                    value={validation.values.percentage_share_tier_4}
                                                    onBlur={validation.handleBlur}
                                                    inputMode="numeric"
                                                    invalid={
                                                        validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleShareChange(e, 4)}
                                                />
                                                <InputGroupText>%</InputGroupText>
                                                {validation.touched.percentage_share_tier_4 && validation.errors.percentage_share_tier_4 ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.percentage_share_tier_4}
                                                    </FormFeedback>
                                                ) : null}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button
                                type="submit"
                                color="primary"
                                disabled={validation.isSubmitting}
                            >
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </FormikProvider>
        </>
    );
};

export default EditVolumePartner;
